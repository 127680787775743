import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Helmet} from "react-helmet";
import { styled } from "@mui/material/styles";
import coverBackground from './../assets/static/images/carer-with-client.jpg'
import FormDialog from "./FormDialogueComponent";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import { usePromiseTracker } from "react-promise-tracker";
import { trackPromise } from 'react-promise-tracker';

import { withStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';

import { headSection, cardsData, howWeHelp, jobsData } from './configs/HomeData';

import LinearProgress from "@material-ui/core/LinearProgress";
import Loader from 'react-loader-spinner';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ExampleTextField = styled(TextField)({
  backgroundColor: "#eee",
  "& .MuiFormControl-root": {
    borderRadius: "4px"
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "#a61930",
    backgroundColor: "white",
    padding: "2px",
    borderRadius: "4px"
  },
  // "&.Mui-focused": {
  //   "& .MuiOutlinedInput-notchedOutline": {
  //     border: "none"
  //   }
  // }
});

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    // backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(14, 0, 25),
    marginTop: '140px',
    backgroundImage: `url(${coverBackground})`,
    backgroundColor: 'rgba(0,0,0,0.4)',
    backgroundBlendMode: 'soft-light',
    backgroundSize: 'cover'
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  textFields: {
    backgroundColor: 'white'
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // boxShadow: '0 1px 3px 0 rgba(37,32,31,.3)',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
    // backgroundSize: 'contain',
    marginBottom: '15px',
    // marginTop: '20px',
  },
  cardContent: {
    flexGrow: 1,
  },
   root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch',
    },
  },
  buttonStyle: {
    width: '30ch'
  },
  drop: {
    width: '30ch',
    margin: '8px',
    paddingTop: '15px',
  },
  footer: {
    backgroundColor: '#2f3030',
    padding: theme.spacing(6),
    color: theme.palette.background.paper,
  },
  preFooter: {
    backgroundColor: '#dfe0e0',
    padding: theme.spacing(4),
    color: theme.palette.background.paper,
  },
}));

const WhiteTextTypography = withStyles({
  root: {
    color: "#3a3b3b"
  }
})(Typography);


export default function Home() {
  const classes = useStyles();
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("success")
  const [notificationMessage, setNotificationMessage] = React.useState("Form submitted! Check your inbox for your first question")
  const [postcode, setPostcode] = React.useState('');
  const [isLoaded, setIsLoaded] = useState(false);

  const [openForm, setOpenForm] = useState(false);

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const vertical = 'top';
  const horizontal = 'center';

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleButtonClick = (page) => {
    handleNavigate(page)

  };

  useEffect(() => {
    window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
  },[]);

  let history = useHistory();

  function handleNavigate(page) {
    if (page === 'services') {
      history.push({
        pathname: '/services',
      });
    }
    if (page === 'contact') {
      history.push({
        pathname: '/contact',
      });
    }
    if (page === 'vacancies') {
      history.push({
        pathname: '/vacancies',
      })
    }

  }

  function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
      promiseInProgress &&
      <div
        style={{
         width: "100%",
         height: "100",
         display: "flex",
         justifyContent: "center",
         alignItems: "center"
        }}
        >
        <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
      </div>
    );
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    // if ((name === '') || (course === '')) {
    //   setOpen(true)
    //   setSeverity('error')
    //   setNotificationMessage('All fields are required')
    //   return;
    // }

    // const a = validateEmail(name)
    // if (!a) {
    //   setOpen(true)
    //   setSeverity('error')
    //   setNotificationMessage('Enter a valid email address')
    //   return;
    // }

    let userSearchQuery = {customer_name: name, contact: phone, siteID: 'excel'}

    const fetchSendDetails = fetch('https://exukonline.com/api/contact-form/', {
      method: 'POST',
      body: JSON.stringify(userSearchQuery),
      headers: {'Content-Type':'application/json'}
    })
    trackPromise(
    fetchSendDetails.then(response => {
      if (response.status === 200) {
        setName('')
        setPhone('')
        setOpen(true)
        setSeverity('success')
        setNotificationMessage('Form submitted! Our team will be in touch')
        setIsLoaded(false)
      }
      return response
    })
    )
  }

  return (
    <React.Fragment>
      <Helmet>
        <meta name="description"
              content="Zimsec and Cambridge learning - Tutorial and Exam Preparation" />
        <meta name="theme-color" content="#055b84" />
        <meta name="msapplication-navbutton-color" content="#055b84"/>
        <meta name="apple-mobile-web-app-status-bar-style" content="#055b84"/>
        <title>Excel Healthcare Services - Supported Living Services</title>
      </Helmet>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{vertical, horizontal}}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity}>
            {notificationMessage}
          </Alert>
        </Snackbar>

        {/*Form Dialog*/}
        <FormDialog
          openForm={openForm}
          handleFormClose={handleCloseForm}
          postcode={postcode}
        />

        {isLoaded ?
          <Container maxWidth="md">
            <div>
              <LinearProgress />
            </div>
          </Container>
          :
            null
        }
        <div className={classes.heroContent}>
          <Container maxWidth="sm">

            {/*</div>*/}
            <div style={{textAlign: 'center', padding: '16px 0px'}}>
              <Typography
                variant="h6"
                align="center"
                color="textPrimary"
                style={{ color: 'white', fontWeight: '500'}}
                gutterBottom>
                Welcome to
              </Typography>
            </div>
            <div style={{textAlign: 'center', padding: '16px 0px'}}>
              <Typography
                variant="h4"
                align="center"
                color="textPrimary"
                style={{ color: 'white', fontWeight: '600'}}
                gutterBottom>
                Excel Healthcare Services
              </Typography>
            </div>
            <div style={{textAlign: 'center'}}>
              <Typography
                variant="h5"
                color="textPrimary"
                style={{ color: '#b7c8e8', fontWeight: '600'}}
                gutterBottom>
                <span
                  // style={{backgroundColor: 'rgb(245, 195, 0)', padding: '0.0em 0.2em 0.0em 0.2em'}}
                >
                  {headSection.topTitle}</span>
              </Typography>
            </div>
            <hr className="hr-line"/>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item xs={12} elevation={0} style={{ paddingBottom: '0px'}}>
                  <div>
                    <Typography
                      variant="h6"
                      align="center"
                      color="textPrimary"
                      style={{ color: 'white', fontWeight: '600', paddingBottom: '0px'}}
                      gutterBottom>
                      Looking for care at home? </Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ textAlign: "center"}}>
                    <ExampleTextField
                      style={{ borderRadius: "4px", marginRight: "8px" }}
                      label="Enter your postcode"
                      size="small"
                      variant="outlined"
                      value={postcode}
                      onChange={(e) => setPostcode(e.target.value)}
                    />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setOpenForm(true)}
                    >
                      Go
                    </Button>
                  </div>
                </Grid>

              </Grid>
            </div>
            <div style={{ display: 'None' }} className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item elevation={0} >
                  <Button variant="contained" color="secondary" onClick={() => handleButtonClick('services')}>
                    More Info
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined"
                          style={{color: 'white', border: '1px solid #fff'}}
                          onClick={() => handleButtonClick('contact')}
                  >
                    Get in Touch
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>

        <Container className={classes.cardGrid} style={{paddingTop: '10px'}} maxWidth="md">
          <WhiteTextTypography
            component="h1"
            variant="h4"
            align="center"
            color="textPrimary"
            style={{fontWeight: 'bold', marginBottom: '40px', marginTop: '64px'}}
            gutterBottom>
              Person centered care from Excel Healthcare Services
            </WhiteTextTypography>
          <div style={{textAlign: 'center', marginBottom: '64px'}}>
            <Typography style={{fontWeight: 'bold', display: 'None'}}>
              We offer supported living services and rehabilitation on the short, medium and long-term
            </Typography>
            <ol className="center">
              <Typography>
                Excel Healthcare Services is a CQC registered independent sector provider working to the standards laid down by
                the Care Quality Commission 2008 and providing publicly funded services to the NHS and Local Authorities.
              </Typography>
            </ol>
            <ol className="center">
              <Typography>
                <a target="_blank" href="https://www.cqc.org.uk/location/1-12088474782">
                  Click here </a> to view our latest CQC report
              </Typography>
            </ol>
          </div>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Card className={classes.card} >
                <CardContent className={classes.cardContent}>
                  <div style={{textAlign: 'center'}}>
                    <WhiteTextTypography
                      component="h6"
                      variant="h6"
                      align="center"
                      style={{fontWeight: 'bold', marginTop: '8px', marginBottom: '16px'}}>
                      If you would like to talk to us or find out how we can help, just enter your details below:
                    </WhiteTextTypography>
                  </div>
                  <form onSubmit={handleSubmit} className={classes.root} noValidate autoComplete="off">
                    <div style={{textAlign: 'center'}}>
                      <TextField
                        id="outlined-name"
                        label="Email"
                        value={name}
                        onChange={handleNameChange}
                        variant="outlined"
                        style={{paddingTop: '15px'}}
                      />
                      <TextField
                        id="outlined-phone"
                        label="Phone"
                        value={phone}
                        onChange={handlePhoneChange}
                        variant="outlined"
                        style={{paddingTop: '15px'}}
                      />
                      <Button size='large'
                              className={classes.buttonStyle}
                              style={{marginTop: '25px', color: 'white'}}
                              type='submit' onClick={() => setIsLoaded(true)} variant="contained" color="secondary">
                            Submit
                      </Button>
                      <LoadingIndicator/>
                    </div>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>


        {/*Our Expertise*/}

        <Container className={classes.cardGrid} maxWidth="md">
          <Typography
            component="h1"
            variant="h4"
            align="center"
            color="textPrimary"
            style={{fontWeight: 'bold', marginBottom: '40px'}}
            gutterBottom>
            Our Services
          </Typography>
          <Grid container spacing={4}>
            {cardsData.map((card) => (
              <Grid item key={card.id} xs={12} sm={6} md={6}>
                <Card className={classes.card} elevation={0}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={card.remote_image}
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography
                        gutterBottom
                        variant="h6"
                        component="h2"
                        style={{fontWeight: 'bold', textAlign: 'center'}}>
                      {card.title}
                    </Typography>
                    <Divider variant="middle" style={{ marginBottom: '15px', marginTop: '15px'}} />
                    <Typography style={{ textAlign: 'center', color: '#686868'}}>
                      {card.desc}
                    </Typography>
                  </CardContent>
                  <CardActions style={{marginBottom: '20px', alignItems: 'center', justifyContent: 'center'}}>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      style={{fontWeight: 800}}
                      onClick={() => handleButtonClick('services')}>
                      Learn More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>

        <Container className={classes.cardGrid} maxWidth="md">
          <Typography
            component="h1"
            variant="h4"
            align="center"
            color="textPrimary"
            style={{fontWeight: 'bold', marginBottom: '40px'}}
            gutterBottom>
            Work with us
          </Typography>
          <Grid container spacing={4}>
            {jobsData.map((card) => (
              <Grid item key={card.id} xs={12} sm={4} md={4}>
                <Card className={classes.card} elevation={0}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={card.remote_image}
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="h2"
                      style={{fontWeight: 'bold', textAlign: 'center'}}>
                      {card.title}
                    </Typography>
                    <Divider variant="middle" style={{ marginBottom: '15px', marginTop: '15px'}} />
                    <Typography style={{ textAlign: 'center', color: '#686868'}}>
                      {card.desc}
                    </Typography>
                  </CardContent>
                  <CardActions style={{marginBottom: '20px', alignItems: 'center', justifyContent: 'center'}}>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      style={{fontWeight: 800}}
                      onClick={() => handleButtonClick('vacancies')}>
                      Learn More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        {/* End Our Expertise*/}

        <Container className={classes.cardGrid} style={{paddingTop: '10px', display: 'None'}} maxWidth="md">
          <Typography
              component="h1"
              variant="h4"
              align="center"
              color="textPrimary"
              style={{fontWeight: 'bold', marginBottom: '40px'}}
              gutterBottom>
              How We Can Help?
            </Typography>
          <Typography>
            {howWeHelp.howText}
          </Typography>
          <List style={{backgroundColor: 'whitesmoke', marginTop: '20px'}}>
            <Grid container spacing={3}>

              <Grid item xs={12} sm={6}>
                <ListItem>
                <ListItemIcon>
                  <DoneAllIcon />
                </ListItemIcon>
                  <div className='offer'>
                    <ListItemText
                      disableTypography
                      primary={<Typography className='offer' type="body2" style={{ fontWeight: 800, fontSize: '1.1rem', color: '#f5c300'}}>
                        Receive tailored advice for your exact needs</Typography>}
                    />
                  </div>
                </ListItem>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ListItem>
                <ListItemIcon>
                  <DoneAllIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                    primary={<Typography className='offer' type="body2" style={{ fontWeight: 800,  fontSize: '1.1rem', color: 'black'}}>
                      Implementation options available</Typography>}
                />
                </ListItem>
              </Grid>

            </Grid>
          </List>
        </Container>
      </main>
      {/* End footer */}

    </React.Fragment>
  );
}
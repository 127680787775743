import * as React from 'react'
import TextField from '@mui/material/TextField';

export default function Q1Cleaning({handleQ, q1Value}) {
  return (
    <div>
      <div style={{ padding: "8px 0px 20px 0px"}}>
        Our rates vary across postcodes, so we need to know what the postcode of your property is.?
      </div>
      <TextField
        label="Enter Your Postcode"
        variant="outlined"
        fullWidth
        value={q1Value}
        onChange={(e) => handleQ(e, 1)}
      />
    </div>
  )
}
import React from "react";


export const sectionOne = {
  topTitle: 'How We Help',
  topSubTitle: 'Our wealth of experience ensures that all our clients and candidates receive ' +
    'the highest quality services at affordable rates. Excel Health Care Services offers ' +
    'specialized healthcare services, providing both temporary and long-term care to clients ' +
    'in their own homes.' +
    'We also offer supported live-in care and healthcare services at ' +
    'establishments throughout the UK',
  cardOneTitle: 'Home Care',
  cardOneDesc: [
 'At Excel Healthcare Services, our unwavering commitment is to deliver a service that revolves around your ' +
 'individual requirements, desires, and ...'
  ],
  cardTwoTitle: 'Supported Living',
  cardTwoDesc: [
  'With a focus on your well-being, Excel Healthcare Services is dedicated to providing a tailored supported living service that ' +
  'caters to your individual needs, aspirations, and ...'
      ],
  cardThreeTitle: 'Domestic Care',
  cardThreeDesc: [
  'Putting your comfort first, Excel Healthcare Services Ltd specializes in delivering customized domestic care services that perfectly ' +
  'align with your specific needs, preferences, and ...'
  ],
  cardFourTitle: 'Specialist Care',
  cardFourDesc: [
    'Our Care Teams undergo extensive training to effectively address a wide spectrum of specialised needs. ' +
    'We possess a deep understanding of the unique symptoms and ...'
  ]
}

export const detailSection = {
  topTitle: 'IT Support & Software Development',
  topSubTitle: 'We provide cost-effective IT support services, we have experience in implementing and supporting\n' +
    '          Microsoft-based IT infrastructures. We build and maintain software by leveraging cutting edge technology.\n' +
    '          In short, we provide an end-to-end support for all your software needs.',
  cardOneTitle: 'IT Support',
  cardOneDesc: [
    "Excel healthcare services  Ltd provides Domiciliary care for our clients’ needs to be met within their communities.  " +
    "We also provide health and care staff to other agency like local authorities and care homes.\n" +
    "Domiciliary Care",
    "We provide service by supporting clients to maintain their personal hygiene and their daily living tasks within their own environment. " +
    "Excelhealth Care Ltd will also be supporting adults at risk living in various schemes which include Supported Living, " +
    "Extra Care Housing and permanent placements.",
    "We offer premium care services through our experienced and skilled staffs who are dedicated in meeting the needs of the service users. " +
    "All these services are achieved by promoting a high standard of services delivery, " +
    "which embraces fundamental principles of Good Care practices and is witnessed and evaluated through the practice, " +
    "conduct and control of quality care in the domestic environment.",
    "Excel Healthcare Services Ltd is registered with Care Quality Commission (CQC)."
  ],
  cardTwoTitle: 'Software Development',
  cardTwoDesc: [
    "We provide competent and qualified \"Agency Staff or locums\" to our clients by attracting, training, and retaining" +
    " qualified and experienced nurses and carers who embody the Excel Healthcare culture of professional care delivery " +
    "and best outcomes. For the provision of temporary and permanent healthcare workers, we operate as a national and " +
    "international recruitment business and employment agency.",
    "In an industry where great service truly saves lives, regulatory legislation for the recruitment of healthcare " +
    "personnel is a crucial concern. The personnel at Excel Healthcare Services, as well as our clients, " +
    "understand how important it is for us to follow these rules and regulations.",
    "The international Ethical Recruitment Charter is enshrined in the UK code of Practice for International " +
    "Recruitment of Health and Social Care Professionals in England, and Excel Healthcare Services follows it. ",
    "Understanding our clients and employees in order to meet their unique needs has always been a key component of " +
    "our success at Excel Healthcare Services. To achieve ongoing quality improvement in addressing your requirements," +
    " our management encourages input and stays current with reforms in the health and social care sectors."
  ]
}
import React, { useEffect } from 'react';
import Main from './components/MainComponent'
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { initGA } from './lib/tracking'

function App() {

  useEffect(() => {
    // Initialize Google Analytics with your tracking ID
    const trackingID = 'G-67WQTWL6LP';
    initGA(trackingID);
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Main/>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { Component } from "react";
import PrimarySearchAppBar from './PrimarySearchAppBar'
import { Switch, Route } from "react-router-dom";
import Home from './Home'
import About from './About'
import Services from './Services'
import Contact from './Contact'
import Footer from './Footer'
import Pricing from './Pricing'
import Vacancies from './Vacancies';
import JobPost from './JobPost';
import Policies from "./Policies";
import HomeCarePage from './pages/HomeCarePage'
import CookieConsent from "react-cookie-consent";
import JobApplicationPage from "./pages/JobApplicationPage";
import DomesticCleaningPage from "./pages/DomesticCleaningPage";

class Main extends Component {
  render() {
    return (
      <div>
        {/*<ContactAppBar />*/}
        <PrimarySearchAppBar />

        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/about-us' exact component={About} />
          <Route path='/services' exact component={Services} />
          <Route path='/services/home-care' exact component={HomeCarePage} />
          <Route path='/services/home-cleaning/:city' exact component={DomesticCleaningPage} />
          <Route path='/contact' exact component={Contact} />
          <Route path='/pricing' exact component={Pricing} />
          <Route path='/vacancies' exact component={Vacancies} />
          <Route path='/job/:id' exact component={JobPost} />
          <Route path='/job-application' exact component={JobApplicationPage} />
          <Route path='/policies' exact component={Policies} />
        </Switch>

        <Footer />
        <CookieConsent style={{ background: 'rgba(0, 0, 0, 0.5)' }} buttonStyle={{ backgroundColor: '#B93703', color: 'white'}}>This website uses cookies to enhance the user experience.</CookieConsent>
      </div>
    )
  }
}

export default Main;
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(services, prices) {
  return { services, prices };
}

const rows = [
  createData('Regular weekly house cleaning', 'From £15.95 per hr'),
  createData('Regular fortnightly house cleaning', 'From £16.20 per hr'),
  createData('6 hours maximum for a one-off clean', '£30 booking fee plus £10 per hr'),
];

export default function PriceTable() {
  return (
    <TableContainer>
      <Table sx={{ minWidth: "90%"}} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{fontWeight: "bold", backgroundColor: "whitesmoke"}}>
              Cleaning Services
            </TableCell>
            <TableCell sx={{fontWeight: "bold", backgroundColor: "whitesmoke"}}>
              Prices
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.services}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.services}
              </TableCell>
              <TableCell>{row.prices}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

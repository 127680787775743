import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import QuestionOne from "./helpers/client/QuestionOneClient";
import QuestionTwo from "./helpers/client/QuestionTwoClient";
import Typography from "@material-ui/core/Typography";
import MuiAlert from '@material-ui/lab/Alert';
import QuestionThree from "./helpers/client/QuestionThreeClient";
import QuestionFour from "./helpers/QuestionFour";
import {useState} from "react";
import QuestionFive from "./helpers/QuestionFive";
import SubmitInquiry from "./helpers/SubmitInquiry";
import {trackPromise} from "react-promise-tracker";

const q = {
  1: 'What type of care is required?',
  2: 'How many days per week is care required?',
  3: 'Which of the following do you need help with?',
  4: 'When is the care required?', // QuestionFour.js
  5: 'Contact number or Email' // QuestionFive.js
}
function Alert(props) {
  return <MuiAlert {...props} />;
}

export default function FormDialog({ openForm, handleFormClose, postcode }) {
  const [value, setValue] = React.useState('');
  const [value2, setValue2] = React.useState('');
  const [value3, setValue3] = useState({
          continence: false,
          cooking: false,
          domestic: false,
          medication: false,
          personal: false,
          other: false
        });
  const [value4, setValue4] = React.useState('');
  const [value5, setValue5] = React.useState('');
  const [error, setError] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState(1);


  const handleCheckboxChange = (event) => {
    setValue3({
      ...value3,
      [event.target.name]: event.target.checked,
    });
  };

  const handleNext = (event) => {
    const stepValueDict = {1: value, 2: value2, 3: value3, 4: value4, 5: value5}
    const stepValue = stepValueDict[activeStep]
    if (activeStep === 3 && Object.values(stepValue).every(val => val === false)) {
      setError(true);
    } else if (stepValue === '') {
      setError(true);
    } else if (activeStep === 6) {
      handleFormClose()
      setActiveStep(1)
    } else {
      setError(false);
      setActiveStep(activeStep + 1);
    }
  }

  const handleSubmit = (event) => {
    const userFormDetails = {};
    userFormDetails[q[1]] = value;
    userFormDetails[q[2]] = value2;
    userFormDetails[q[3]] = value3;
    userFormDetails[q[4]] = value4;
    userFormDetails[q[5]] = value5;
    userFormDetails['post-code'] = postcode
    userFormDetails['siteID'] = 'excel'

    const fetchSubmitDetails = fetch('https://exukonline.com/api/contact-form/', {
      method: 'POST',
      body: JSON.stringify(userFormDetails),
      headers: {'Content-Type':'application/json'}
    })
    trackPromise(
      fetchSubmitDetails.then(response => {
        if (response.status === 200) {
          setValue('')
          setValue2('')
          setValue4('')
          setValue5('')
        }
        return response
      })
    )
  }

  const handleBack = (event) => {
    setActiveStep(activeStep - 1)
  }

  const handleQChange = (event, step) => {
    switch (step) {
      case 1:
        setValue(event.target.value);
        break;
      case 2:
        setValue2(event.target.value);
        break;
      case 4:
        setValue4(event.target.value);
        break;
      case 5:
        setValue5(event.target.value);
        break;
      default:
        setValue(event.target.value);
        break;
    }
  };


  return (
    <div>
      <Dialog
        open={openForm}
        onClose={handleFormClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            style={{ fontWeight: "600"}}
            >
            { q[activeStep] }
          </Typography>

        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { error ?
              (<div style={{ padding: "16px 0px", display: ""}}>
                <Alert severity="error">
                  Please provide an answer.
                </Alert>
              </div>)
              : null
            }
            {activeStep === 1 && (
              <QuestionOne
                handleQ={handleQChange}
                q1Value={value}
              />
            )}
            {activeStep === 2 && (
              <QuestionTwo
                handleQ={handleQChange}
                q2Value={value2}
              />
            )}
            {activeStep === 3 && (
              <QuestionThree
                handleQ={handleCheckboxChange}
                q3Value={value3}
              />
            )}
            {activeStep === 4 && (
              <QuestionFour
                handleQ={handleQChange}
                q4Value={value4}
              />
            )}
            {activeStep === 5 && (
              <QuestionFive
                handleQ={handleQChange}
                q5Value={value5}
              />
            )}
            {activeStep === 6 && (
              <SubmitInquiry
                handleSubmit={handleSubmit}
              />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: "20px" }}>
          <Grid container>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                color="secondary"
                className={activeStep === 6 ? 'disappear' : 'mui-buttons'}
                onClick={handleBack}
                disabled={activeStep === 1}
              >
                Back</Button>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right"}}>
              <Button
                variant="contained"
                color="secondary"
                className="mui-buttons"
                onClick={handleNext}
              >
                {activeStep === 6 ? 'Close': 'Continue'}</Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export const headSection = {
  logo: 'here',
  topTitle: 'Your Health, Our Priority',
  topSubTitle: '- Wakefield'
}

export const cardsData = [
  {'title': 'Domiciliary care', 'price': 250,
    'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/m-6u-21-10-21-12-10-03-exam-revision.jpg',
    'desc': 'We provide Domiciliary care for our clients’ needs to be met within ' +
        'their communities. We also provide ...', 'id': 2},
  {'title': 'Recruitment', 'price': 100,
  'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/m-6u-21-10-21-12-09-33-exam-revision.jpg',
  'desc': 'We provide competent and qualified "Agency Staff or locums" to our clients by ...', 'id': 1}
  ];

export const jobsData = [
  {'title': 'Full Time', 'price': 250,
    'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/m-6u-02-06-22-15-55-13-exam-revision.jpg',
    'desc': 'Full time roles ...', 'id': 2},
  {'title': 'Part Time', 'price': 100,
    'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/m-6u-02-06-22-15-55-13-exam-revision.jpg',
    'desc': 'Part time roles ...', 'id': 1},
  {'title': 'Temporary', 'price': 100,
    'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/m-6u-02-06-22-15-55-13-exam-revision.jpg',
    'desc': 'Temporary roles ...', 'id': 1}
];

export const howWeHelp = {
  howText: 'We are passionate about helping clients on how they can utilise their information technology (IT)' +
    ' to optimally achieve their business goals. Let our consultants soothe your IT headache.'
}
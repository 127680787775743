import * as React from 'react'
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

export default function QuestionTwoClient({handleQ, q2Value}) {
  return (
    <div>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={q2Value}
          onChange={(e) => handleQ(e, 2)}
        >
          <FormControlLabel value="1day" control={<Radio />} label="1 day" />
          <FormControlLabel value="2-3days" control={<Radio />} label="2-3 days" />
          <FormControlLabel value="4-6days" control={<Radio />} label="4-6 days" />
          <FormControlLabel value="other" control={<Radio />} label="Other" />
        </RadioGroup>
      </FormControl>
    </div>
  )
}
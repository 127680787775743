import React from 'react';
import { Container, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  sectionTitles: {
    paddingBottom: '30px',
    fontWeight: 'bolder',
    paddingTop: '64px',
  },
  question: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    paddingBottom: '8px',
  },
  answer: {
    fontStyle: 'italic',
    paddingTop: '4px',
    paddingBottom: '48px',
  },
}));

const FAQSectionComponent = ({ faqs, setOpenForm }) => {
  const classes = useStyles();

  return (
    <Container maxWidth="md">
      <Typography
        component="h1"
        variant="h5"
        align="center"
        color="textPrimary"
        className={classes.sectionTitles}
        gutterBottom
      >
        Frequently Asked Questions
      </Typography>
      <Typography>
        {faqs.map((faq, index) => (
          <React.Fragment key={index}>
            <div className={classes.question}>
              {faq.question}
            </div>
            <div className={classes.answer}>
              {faq.answer}
            </div>
          </React.Fragment>
        ))}
      </Typography>
    </Container>
  );
};

export default FAQSectionComponent;

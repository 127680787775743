export const policies = [
  {'policyName': 'Whistleblowing',
    'policyDesc': 'Whistleblowing is the act of disclosing information about wrongdoing in the workplace',
  'policyPDF': require("../assets/static/docs/EHS-Whistleblowing-Policy-and-Procedure.pdf")
  },
  {'policyName': 'Administration of medicines',
    'policyDesc': 'Administration of medicines policy and procedure',
  'policyPDF': require("../assets/static/docs/EHS-Administration-of-medicines-policy-and-procedure.pdf")
  },
  {'policyName': 'Complaints Policies',
    'policyDesc': 'Complaints, suggestions and compliment policy and procedure',
  'policyPDF': require("../assets/static/docs/EHS-Complaints-Policy.pdf")
  },
  {'policyName': 'Lone Worker Policy',
    'policyDesc': 'Excel Health lone worker policy',
    'policyPDF': require("../assets/static/docs/EHS-Lone-Worker-Policy.pdf")
  },
  {'policyName': 'Service User Handbook',
    'policyDesc': 'Excel Healthcare Services Service Users Handbook',
    'policyPDF': require("../assets/static/docs/EHS-Service-Users-Handbook-Domicilary.pdf")
  },
  {'policyName': 'Safeguarding Policy and Procedure',
    'policyDesc': 'Excel Healthcare Services Safeguarding policy and procedure',
    'policyPDF': require("../assets/static/docs/EHS-Safeguarding-policy-and-procedure.pdf")
  },
  {'policyName': 'Code of Conduct for Workers',
    'policyDesc': 'Excel Healthcare Services Code of Conduct for workers',
    'policyPDF': require("../assets/static/docs/EHS-Code-of-Conduct-for-Workers-1.pdf")
  },
  {'policyName': 'Wakefield Whistleblowing policy',
    'policyDesc': 'Wakefield Council whistleblowing policy',
    'policyPDF': require("../assets/static/docs/Wakefield-whisteblowing-policy-2021-1.pdf")
  },
  {'policyName': 'Wakefield Whistleblowing policy - appendix 1',
    'policyDesc': 'Wakefield Council whistleblowing policy - Appendix 1',
    'policyPDF': require("../assets/static/docs/Wakefield-whistleblowing-appendix-1-six-categories-wrongdoing-1.pdf")
  },
  {'policyName': 'Wakefield Whistleblowing policy - appendix 2',
    'policyDesc': 'Wakefield Council whistleblowing policy - Appendix 2',
    'policyPDF': require("../assets/static/docs/Wakefield-whistleblowing-appendix-2-list-prescibed-persons-1.pdf")
  },
  {'policyName': 'Wakefield Whistleblowing policy - appendix 3',
    'policyDesc': 'Excel Healthcare Services Code of Conduct for workers',
    'policyPDF': require("../assets/static/docs/Wakefield-whistleblowing-appendix-3-highlighted-wrongdoing-to-children-vulnerable-adults-1.pdf")
  },
  {'policyName': 'Staff Loans and Advances Policy and Procedure',
    'policyDesc': 'Staff Loans and Advances Policy and Procedure',
    'policyPDF': require("../assets/static/docs/EHS-Staff-Loans-and-Advances-Policy-and-Procedure.pdf")
  },
  {'policyName': 'Wakefield Safeguarding CYP During Coronavirus',
    'policyDesc': 'Wakefield Safeguarding CYP During Coronavirus',
    'policyPDF': require("../assets/static/docs/Wakefield-safeguarding-CYP-during-coronavirus-partnership-approach-V6-v2-1.pdf")
  },
  {'policyName': 'ECM and Accountability Policy',
    'policyDesc': 'Excel Healthcare Services ECM and Accountability Policy',
    'policyPDF': require("../assets/static/docs/EHS-ECM-and-Accountability-Policy-protected.pdf")
  },
  {'policyName': 'Supervision Policy',
    'policyDesc': 'Excel Healthcare Services Supervision Policy',
    'policyPDF': require("../assets/static/docs/EHS-Supervision-policy-protected.pdf")
  },
  {'policyName': 'Handling of Service Users Money',
    'policyDesc': 'Excel Healthcare Services Handling of Service User Money',
    'policyPDF': require("../assets/static/docs/EHS-Handling-of-service-users-money-protected.pdf")
  },
  {'policyName': 'Gifts and Legacies Policy',
    'policyDesc': 'Excel Healthcare Services Gifts and Legacy Policy',
    'policyPDF': require("../assets/static/docs/EHS-Gifts-and-Legacies-Policy.pdf")
  },
  {'policyName': 'Recruitment and Selection Policy',
    'policyDesc': 'Excel Healthcare Services Recruitment and Selection Policy',
    'policyPDF': require("../assets/static/docs/EHS-Recruitment-and-Selection.pdf")
  },
  {'policyName': 'Staff Loans and advances application form',
    'policyDesc': 'Excel Healthcare Services staff loans request form',
    'policyPDF': require("../assets/static/docs/Staff-Loans-and-Advances-Application-Form-Blank.docx")
  },
  {'policyName': 'Staff Handbook',
    'policyDesc': 'Excel Healthcare Services staff handbook',
    'policyPDF': require("../assets/static/docs/EHS-Employee-Handbook-Excel-Healthcare-Services-Ltd.pdf")
  },
  {
    'policyName': 'Risk Assessment Policy',
    'PolicyDesc': 'Excel Healthcare Service Risk Assessment Policy',
    'policyPDF': require("../assets/static/docs/EHS-Risk-Assessment-Policy.pdf")
  },
  {
    'policyName': 'Financial Irregularities',
    'PolicyDesc': 'Excel Healthcare Financial Irregularities Policy',
    'policyPDF': require("../assets/static/docs/EHS-Financial-Irregularities.pdf")
  },
  {
    'policyName': 'Daily Log Recording Procedure',
    'PolicyDesc': 'Excel Healthcare Service Daily Log Recording Procedure',
    'policyPDF': require("../assets/static/docs/EHS-Daily-Log-Recording-Procedure.pdf")
  },
  {
    'policyName': 'Modern Slavery',
    'PolicyDesc': 'Excel Healthcare Service Modern Slavery Policy',
    'policyPDF': require("../assets/static/docs/ESH-Modern-Slavery.pdf")
  },
  {
    'policyName': 'Accessible Information and Communication',
    'PolicyDesc': 'Excel Healthcare Service Accessible Information and Communication Policy',
    'policyPDF': require("../assets/static/docs/EHS-accessible-information-and-communication-policy.pdf")
  },
  {
    'policyName': 'Complaints Policy - Easy to read',
    'PolicyDesc': 'Excel Healthcare Service Complaints Policy - Easy to read',
    'policyPDF': require("../assets/static/docs/EHS-Complaints-Policy-easy-read-version.pdf")
  },
  {
    'policyName': 'Infection Control Policy',
    'PolicyDesc': 'Excel Healthcare Service Infection Control Policy',
    'policyPDF': require("../assets/static/docs/EHS-Infection-Control-Policy.pdf")
  },
  {
    'policyName': 'Application form',
    'PolicyDesc': 'Excel Healthcare Service Application Form',
    'policyPDF': require("../assets/static/docs/EHS-application-form-1.pdf")
  },
  {
    'policyName': 'Staff Health Questionnaire',
    'PolicyDesc': 'Excel Healthcare Service Stqff Health Questionnaire',
    'policyPDF': require("../assets/static/docs/EHS-Staff-Health-Questionnaire.pdf")
  }
]
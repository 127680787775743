import * as React from 'react'
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

export default function Q2Cleaning({handleQ, q2Value}) {
  return (
    <div>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={q2Value}
          onChange={(e) => handleQ(e, 2)}
        >
          <FormControlLabel value="Weekly" control={<Radio />} label="Weekly" />
          <FormControlLabel value="Fortnightly" control={<Radio />} label="Fortnightly" />
          <FormControlLabel value="Once" control={<Radio />} label="One off" />
          <FormControlLabel value="Other" control={<Radio />} label="Other" />
        </RadioGroup>
      </FormControl>
    </div>
  )
}
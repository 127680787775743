import React, {useEffect, useState} from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {Link, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import wallPaper from "../../assets/static/images/clean-house-excel.jpg";
import imgCleanHouse from "../../assets/static/images/icons/icons8-clean-house-50.png";
import imgIroning from "../../assets/static/images/icons/icons8-ironing-64.png";
import imgPolish from "../../assets/static/images/icons/icons8-vacuum-80.png";
import imgRegClean from "../../assets/static/images/icons/icons8-clean-house-64.png";
import imgSpray from "../../assets/static/images/icons/icons8-spray-100.png";
import excelCarer from "../../assets/static/images/excel-cleaning-laundry.jpg";
import PriceTable from "../PriceTable";
// import FormDialog from "./../FormDialogueComponent";
import DomCleanForm from "./../forms/DomCleanForm";
import FAQSectionComponent from "../FAQSectionComponent";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import Divider from '@mui/material/Divider';
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(16, 0, 6),
    backgroundImage: `url(${wallPaper})`,
    backgroundColor: 'rgba(0, 0, 0, 0.13)',
    backgroundBlendMode: 'darken',
    backgroundSize: 'cover'
  },
  iconClean: {
    height: '50px'
  },
  gridContent: {
    textAlign: 'center'
  },
  iconIron: {
    height: '50px'
  },
  iconSpray: {
    backgroundImage: `url(${imgSpray})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100px'
  },
  iconPolish: {
    height: '50px'
  },
  iconRegClean: {
    height: '50px'
  },
  personalCareContent: {
    backgroundImage: `url(${excelCarer})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    minHeight: '250px',
    borderRadius: '4px'
  },
  sections: {
  },
  sectionsOne: {
    backgroundColor: 'white',
    paddingBottom: '40px'
  },
  sectionsTwo: {
    backgroundColor: '#f9f9fa'
  },
  sectionTitles: {
    paddingBottom: '30px',
    fontWeight: 'bolder',
    paddingTop: '64px',
  },
  question: {
    fontWeight: "bold",
    fontSize: "1.2rem",
    paddingBottom: "8px"
  },
  answer: {
    fontStyle: "italic",
    paddingTop: "4px",
    paddingBottom: "48px"
  },
  coreValues: {
    fontWeight: 'bold'
  },
  itemList: {
    fontSize: '1rem'
  }
}));


export default function DomesticCleaningPage(props) {

  const classes = useStyles();
  const [openForm, setOpenForm] = useState(false);
  const [postcode, setPostcode] = React.useState('');
  const [city, setCity] = React.useState('');
  const params = useParams()

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const cleaningFaqs = [
    {
      question: `What types of domestic cleaning services do you provide in ${city}?`,
      answer: `We offer a wide range of domestic cleaning services in ${city}, including regular house cleaning, 
      deep cleaning, end-of-tenancy cleaning, and one-off cleans. Our services are tailored to meet your unique 
      needs and ensure your home is spotless.`
    },
    {
      question: 'Do I need to provide cleaning supplies?',
      answer: 'Our cleaners can use either their own high-quality cleaning products or the supplies you prefer. ' +
        'You can specify your preference when booking your service.'
    },
    {
      question: "How do I find a reliable house cleaner near me?",
      answer: "Finding a reliable house cleaner is simple with us! Our team of local, " +
        "vetted cleaners is experienced and trustworthy. Just book online, and we'll send " +
        "the perfect cleaner to meet your needs."
    },
    {
      question: "What are the benefits of hiring a professional cleaning company?",
      answer: "Hiring a professional cleaning company ensures that your home is cleaned to a high standard " +
        "by experienced cleaners using quality products. It saves you time, reduces stress, " +
        "and provides a healthier living environment."
    },
    {
      question: `How much does it cost for house cleaning services in ${city}?`,
      answer: `Our house cleaning services in ${city} are competitively priced and offer great value for money. 
      Visit our pricing page to view our rates and choose the best option for your cleaning needs.`
    }]

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
    setCity(capitalizeFirstLetter(params.city) || '');
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <meta name="description"
              content="Cleaning Services" />
        <title>Excel Healthcare Services - Domestic Cleaning Services</title>
      </Helmet>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Breadcrumb>
            <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
            <BreadcrumbItem active>Home Cleaning</BreadcrumbItem>
          </Breadcrumb>
          <Container maxWidth="md">
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="textPrimary"
              style={{fontWeight: 'bold', color: 'white'}}
              gutterBottom>
              <span className="page-header">Home Cleaning Services in { city }</span>
            </Typography>
            <div style={{
              borderRadius: '8px',
              padding: '24px 40px',
              margin: '72px 0px',
              border: '1px solid #656565',
              backgroundColor: 'white'}}>
              <Typography
                component="h6"
                variant="h6"
                align="center"
                color="textPrimary"
                style={{ fontWeight: 500 }}
                >
                Looking for a reliable domestic cleaner in <span className="bold-txt">{city}</span>?
                As one of the UK’s most trusted cleaning companies,
                we handle thousands of home cleaning visits every week.
                Book a thoroughly vetted local cleaner today and experience a spotless home!
              </Typography>
              <div style={{ textAlign: 'center', margin: '40px 0px'}}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className="mui-buttons"
                      onClick={() => setOpenForm(true)}
                      disableElevation>
                      Book a cleaner online </Button>
                  </Grid>
                </Grid>
              </div>
              <Typography
                component="h5"
                variant="h5"
                align="center"
                color="textPrimary"
                style={{ fontWeight: 600 }}
              >
                For a free personalised quote,
                <br/> call Excel on
                <br/>
                <a href="tel:07380308799" style={{ textDecoration: 'none', color: 'inherit' }}>
                  07380 308799
                </a>
              </Typography>
            </div>
          </Container>
        </div>
      </main>
      <div style={{padding: "16px 0px"}}>
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid className={classes.gridContent} item xs={3}>
              <img src={imgIroning} alt="Iron Icon" className={classes.iconIron} />
              <Typography color="secondary" style={{fontWeight: 'bold'}}>
                Ironing
              </Typography>
            </Grid>
            <Grid className={classes.gridContent} item xs={3}>
              <img src={imgCleanHouse} alt="Clean House" className={classes.iconClean}/>
              <Typography style={{fontWeight: 'bold', color: '#c9c9c9'}}>
                One Off Clean
              </Typography>
            </Grid >
            <Grid className={classes.gridContent} item xs={3}>
              <img src={imgPolish} alt="Polishing" className={classes.iconPolish}/>
              <Typography style={{fontWeight: 'bold', color: '#c9c9c9'}}>
                Vacuum
              </Typography>
            </Grid>
            <Grid className={classes.gridContent} item xs={3}>
              <img src={imgRegClean} alt="Clean House" className={classes.iconRegClean}/>
              <Typography style={{fontWeight: 'bold', color: '#c9c9c9'}}>
                Regular Clean
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div>
        <div className={classes.sectionsOne}>
          <Container maxWidth="md" style={{paddingBottom: '40px'}}>
            <DomCleanForm
              openForm={openForm}
              handleFormClose={handleCloseForm}
              postcode={postcode}
            />
            <Typography
              component="h1"
              variant="h5"
              align="center"
              color="textPrimary"
              className={classes.sectionTitles}
              gutterBottom>
              How much does a house cleaner cost in { city }?
            </Typography>
            <Typography>
              Enjoy affordable cleaning services tailored to your needs.
              Choose from our flexible pricing options and schedule your local cleaner today for a sparkling home!
            </Typography>
            <br/>
            {/*Price table*/}
            <PriceTable/>
            <br/>
            <div style={{ textAlign: "center"}}>
              <Button
                variant="contained"
                color="secondary"
                className="mui-buttons"
                onClick={() => setOpenForm(true)}
                disableElevation>
                Book online </Button>
            </div>
          </Container>
        </div>

        {/*Services we offer*/}
        <div className={classes.sectionsTwo}>
          <Container maxWidth="md" style={{paddingTop: '48px', paddingBottom: '48px'}}>
            <Grid container spacing={3}>
              <Grid className={classes.personalCareContent} item xs={12} sm={6}>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography style={{fontSize: '1.1rem', paddingBottom: '8px'}} className={classes.coreValues}>
                  Cleaning services we offer:
                </Typography>
                <ListItem>
                  <CheckCircleOutlineIcon style={{ marginRight: '8px', color: '#a6182f' }} />
                  <span className={classes.itemList}>Floors & Carpets </span>
                </ListItem>
                <Divider/>
                <ListItem>
                  <CheckCircleOutlineIcon style={{ marginRight: '8px', color: '#a6182f' }} />
                  <span className={classes.itemList}>Kitchen including oven cleaning</span>
                </ListItem>
                <Divider/>
                <ListItem>
                  <CheckCircleOutlineIcon style={{ marginRight: '8px', color: '#a6182f' }} />
                  <span className={classes.itemList}>Bathrooms </span>
                </ListItem>
                <Divider/>
                <Divider/>
                <ListItem>
                  <CheckCircleOutlineIcon style={{ marginRight: '8px', color: '#a6182f' }} />
                  <span className={classes.itemList}>Internal Windows </span>
                </ListItem>
                <Divider/>
                <ListItem>
                  <CheckCircleOutlineIcon style={{ marginRight: '8px', color: '#a6182f' }} />
                  <span className={classes.itemList}>Vacuuming, Polishing and Dusting </span>
                </ListItem>
                <Divider/>
                <ListItem>
                  <CheckCircleOutlineIcon style={{ marginRight: '8px', color: '#a6182f' }} />
                  <span className={classes.itemList}>Laundry & Ironing</span>
                </ListItem>
              </Grid>
            </Grid>

          </Container>
        </div>

        {/*FAQs*/}
        <div className={classes.sectionsOne}>
          <FAQSectionComponent
            faqs={cleaningFaqs}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel";


export default function Q3Cleaning({handleQ, q3Value}) {
  return (
    <div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={q3Value.laundry}
              onChange={handleQ}
              name="laundry"
            />
          }
          label="Laundry"
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={q3Value.carpets}
              onChange={handleQ}
              name="carpets"
            />
          }
          label="Carpets"
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={q3Value.oven}
              onChange={handleQ}
              name="oven"
            />
          }
          label="Oven"
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={q3Value.general}
              onChange={handleQ}
              name="general"
            />
          }
          label="General House Cleaning"
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={q3Value.tenancy}
              onChange={handleQ}
              name="tenancy"
            />
          }
          label="End of tenancy"
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={q3Value.other}
              onChange={handleQ}
              name="other"
            />
          }
          label="Other"
        />
      </div>
    </div>
  )
}
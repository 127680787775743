import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel";


export default function QuestionThreeClient({handleQ, q3Value}) {
  return (
    <div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={q3Value.continence}
              onChange={handleQ}
              name="continence"
            />
          }
          label="Continence support"
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={q3Value.cooking}
              onChange={handleQ}
              name="cooking"
            />
          }
          label="Cooking"
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={q3Value.domestic}
              onChange={handleQ}
              name="domestic"
            />
          }
          label="Housekeeping"
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={q3Value.medication}
              onChange={handleQ}
              name="medication"
            />
          }
          label="Medication"
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={q3Value.personal}
              onChange={handleQ}
              name="personal"
            />
          }
          label="Personal care (washing, bathing, hair & makeup)"
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={q3Value.other}
              onChange={handleQ}
              name="other"
            />
          }
          label="Other"
        />
      </div>
    </div>
  )
}
export const data = {
  whoWeAre: "Excel Healthcare Services Ltd was founded as a domiciliary care agency with the primary goal of \n" +
    "delivering high-quality nursing and personal care services to all service users in England who are \n" +
    "residing in various care facilities on a permanent or temporary basis.\n" +
    "Excel Healthcare Services Ltd is dedicated to providing a flexible, responsive, and non-discriminatory \n" +
    "service while respecting each user's right to independence, privacy, dignity, fulfilment, and the freedom\n" +
    "to make informed decisions and take risks.\n" +
    "Our fundamental goal is to work in collaboration with service users, their families, and other agencies \n" +
    "to provide high-quality care while promoting independence within the framework of everyday values.\n" +
    "Excel Healthcare Services is a CQC registered independent sector provider working to the standards\n" +
    "laid down by the Care Quality Commission 2008 and providing publicly funded services to the NHS \n" +
    "and Local Authorities.\n"
}
import * as React from 'react'
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

export default function QuestionOneClient({handleQ, q1Value}) {
  return (
    <div>
      <FormControl>
        {/*<FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel>*/}
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={q1Value}
          onChange={(e) => handleQ(e, 1)}
        >
          <FormControlLabel value="overnight" control={<Radio />} label="Overnight care" />
          <FormControlLabel value="live-in" control={<Radio />} label="Live-in care" />
          <FormControlLabel value="visiting" control={<Radio />} label="Visiting care" />
          <FormControlLabel value="other" control={<Radio />} label="Other" />
        </RadioGroup>
      </FormControl>
    </div>
  )
}